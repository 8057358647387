import React from 'react';
import { useIntl } from 'react-intl';
import { ErrorProps } from 'next/error';
import { NextPage } from 'next';
import PageNext from '../src/components/Page/PageNext';
import Empty from '../src/components/Empty/Empty';
import Browser from '../src/components/Icons/Browser/Browser';

const ErrorPage: NextPage<ErrorProps> = ({ statusCode }) => {
  const intl = useIntl();
  if (statusCode === 404) {
    return (
      <PageNext title={intl.formatMessage({ id: 'app.error.pagenotfound' })}>
        <Empty
          icon={<Browser size={64} />}
          message={intl.formatMessage({ id: 'app.error.pagenotfound' })}
        />
      </PageNext>
    );
  }

  return (
    <PageNext title={`${intl.formatMessage({ id: 'app.error.title' })} ${statusCode}`}>
      <Empty
        icon={<Browser size={64} />}
        message={`{intl.formatMessage({ id: 'app.error.title' })} ${statusCode}`}
        description={intl.formatMessage({ id: 'app.error.fixinprogress' })}
      />
    </PageNext>
  );
};

ErrorPage.getInitialProps = async (context): Promise<ErrorProps> => {
  const { res, err } = context;
  if (res) {
    return { statusCode: res.statusCode! };
  } if (err && err.statusCode) {
    return { statusCode: err.statusCode! };
  }

  return { statusCode: 404 };
};

export default ErrorPage;
